/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

const netlifyCmsPaths = {
  resolve: `gatsby-plugin-netlify-cms-paths`,
  options: {
    cmsConfig: `/static/appcoder/config.yml`,
  },
}

const settings = require("./src/util/site.json")
const { createProxyMiddleware } = require("http-proxy-middleware") 

module.exports = {
  developMiddleware: app => {
    app.use(
      "/.netlify/functions/",
      createProxyMiddleware({
        //target: "http://lambda:9000",
        target: "http://localhost:9000",
        pathRewrite: {
          "/.netlify/functions/": "",
        },
      })
    )
  },

  siteMetadata: settings.meta,
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/static/assets/`,
        name: `assets`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/content/`,
        name: `content`,
      },
    },
    {
      resolve: `gatsby-plugin-material-ui`,
      options: {
        stylesProvider: {
          injectFirst: true,
        },
      },
    },
    {
      resolve: `gatsby-plugin-nprogress`,
      options: {
        showSpinner: false,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        gfm: true,
        plugins: [
          netlifyCmsPaths,
          `gatsby-remark-reading-time`,
          {
            resolve: `gatsby-remark-images`,
            options: {
              maxWidth: 800,
              quality: 100,
              showCaptions: true,
              linkImagesToOriginal: false,
              tracedSVG: true,
              loading: "lazy",
            },
          },
          {
            resolve: `gatsby-remark-images-medium-zoom`, 
            options: {
              background: "#000000"
            }
          },
          `gatsby-remark-responsive-iframe`,
          {
            resolve: `gatsby-remark-vscode`,
            options: {
              theme: 'Default Dark+', // Or install your favorite theme from GitHub
              extensions: ['Kotlin','dart-code']
            }
          },
          // {
          //   resolve: `gatsby-remark-prismjs`,
          //   options: {
          //     inlineCodeMarker: null,
          //     aliases: {},
          //     showLineNumbers: false,
          //     noInlineHighlight: false,
          //     // By default the HTML entities <>&'" are escaped.
          //     // Add additional HTML escapes by providing a mapping
          //     // of HTML entities and their escape value IE: { '}': '&#123;' }
          //     escapeEntities: {},
          //   },
          // },
          {
            resolve: `gatsby-plugin-catch-links`,
            options: {
              excludePattern: /(excluded-link|external)/,
            },
          },
          {
            resolve: `gatsby-plugin-typography`,
            options: {
              pathToConfigModule: `src/util/typography`,
            },
          },
          `gatsby-remark-smartypants`,
        ],
      },
    },
    `gatsby-plugin-sass`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-netlify-cms`,
      options: {
        publicPath: `appcoder`,
        htmlTitle: `CMS - Meek Code`,
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: settings.meta.siteUrl,
        stripQueryString: true,
      },
    },
    {
      resolve: `gatsby-plugin-gtag`,
      options: {
        // your google analytics tracking id
        trackingId: settings.meta.google_analytics,
        // Puts tracking script in the head instead of the body
        head: false,
        // enable ip anonymization
        anonymize: true,
      },
    },
    // {
    //   resolve: `gatsby-plugin-google-adsense`,
    //   options: {
    //     publisherId: settings.meta.google_adsense
    //   },
    // },
    {
      resolve: `gatsby-plugin-advanced-sitemap`,
      options: {
        exclude: [
          `/404`,
          `/login`,
          `/login-failed`,
          `/thanks`,
          `/search-result`,
          `/privacy`,
          `/apps/app-temp-dont-deletemodifypublish`,
          `/blog/temp-post-dont-deletemodifypublish`,
          `/offline-plugin-app-shell-fallback`,
      ],
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Meek Code`,
        short_name: `Meek Code`,
        start_url: `/`,
        background_color: `#f7f0eb`,
        theme_color: `#2c292a`,
        display: `standalone`,
        icon: `static/assets/meekcode.png`,
      },
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        mergeSecurityHeaders: true,
      },
    },
    'gatsby-plugin-offline',
  ],
}
